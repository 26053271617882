import { JsonRouteErrorResponse } from "../types";
import { json } from "react-router-dom";

export const throwJsonRouteError: (
  payload: JsonRouteErrorResponse["data"] | string,
  status: number,
) => never = (payload, status) => {
  const payloadBody =
    typeof payload === "string" ? { message: payload } : payload;

  throw json(payloadBody, { status });
};
