import countries from "i18n-iso-countries";
import locale from "i18n-iso-countries/langs/en.json";

countries.registerLocale(locale);

const getFlagEmoji = (countryCode: string) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));

  return String.fromCodePoint(...codePoints);
};

const countryObj = countries.getNames("en");

export const countryOptions = Object.keys(countryObj).map((key) => ({
  value: key,
  label: countryObj[key],
  emoji: getFlagEmoji(key),
}));
