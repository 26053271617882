import { DetailedHTMLProps, ButtonHTMLAttributes, FC } from "react";
import { twMerge } from "tailwind-merge";
import { Spinner } from "./form/Spinner";

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: "default" | "outlined" | "subtle";
  icon?: boolean;
  loading?: boolean;
  autoTextSize?: boolean;
  maxAutoFontSizePx?: number;
  showSpinner?: boolean;
  className?: string;
}

export const Button: FC<Props> = ({
  className,
  showSpinner,
  variant = "default",
  ...props
}) => {
  return (
    <button
      {...props}
      className={twMerge(
        "bg-brand-red-sign-up hover:bg-brand-teal focus:ring-4 focus:outline-none focus:ring-blue-300 w-full rounded-md text-white font-semibold text-lg px-5 py-2.5 flex flex-row justify-center items-center",
        variant === "outlined" &&
          "border-2 border-solid border-neutral-500 bg-transparent hover:bg-neutral-200 text-neutral-600 hover:text-neutral-800",
        showSpinner && "cursor-progress",
        props.disabled && "cursor-not-allowed opacity-60",
        className,
      )}
      type="submit"
      tabIndex={3}
      disabled={showSpinner || props.disabled}
    >
      <Spinner
        className={twMerge("justify-self-start", !showSpinner && "hidden")}
      />
      {props.children}
    </button>
  );
};
