import { FC, PropsWithChildren } from "react";
import { Outlet, useNavigate as useNavigateOriginal } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const ModalBackground: FC<
  PropsWithChildren & {
    className?: string;
    closeOnBackgroundClick?: boolean;
    useNavigateOverride?: typeof useNavigateOriginal;
    onBackgroundClick?: () => void;
  }
> = ({
  children,
  className,
  closeOnBackgroundClick = true,
  useNavigateOverride: useNavigate = useNavigateOriginal,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={twMerge(
        "fixed left-0 top-0 right-0 bottom-0 bg-neutral-800 bg-opacity-80 flex flex-col items-center justify-center z-[1001] overflow-scroll",
        className,
      )}
      onClick={() => closeOnBackgroundClick && navigate("/", { replace: true })}
    >
      {children ? children : <Outlet />}
    </div>
  );
};
