import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

interface Props
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ className, ...props }, ref) => (
    <input
      {...props}
      ref={ref}
      className={twMerge(
        "bg-neutral-100 border border-neutral-400",
        "text-neutral-900 text-md",
        "rounded-md",
        "focus:ring-neutral-600 focus:border-neutral-600",
        "block w-full p-2.5",
        className,
      )}
    />
  ),
);
