import { useRouteError } from "@/lib/react-router-dom";
import { JsonRouteErrorResponse } from "../types";
import { isRouteErrorResponse } from "react-router-dom";

export const useJsonRouteError = () => {
  const error = useRouteError<JsonRouteErrorResponse>();

  if (!isRouteErrorResponse(error)) throw error;

  return error;
};
