import { DetailedHTMLProps, ReactNode, SelectHTMLAttributes } from "react";
import { Controller, Path, PathValue, useFormContext } from "react-hook-form";
import ReactSelect, { SingleValue } from "react-select";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

interface Props<T extends z.ZodSchema<object>>
  extends DetailedHTMLProps<
    SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  name: Path<z.infer<T>>;
  options: {
    label: ReactNode;
    value: PathValue<z.infer<T>, Props<T>["name"]>;
  }[];
}

const controlStyles = twMerge(
  "bg-neutral-100 border border-neutral-400",
  "text-neutral-900 text-md",
  "rounded-md",
  "focus:ring-neutral-600 focus:border-neutral-600",
  "block w-full p-2.5",
);

const menuStyles =
  "bg-neutral-150 mt-1 shadow-md border border-neutral-400 rounded";

export const Select = <T extends z.ZodSchema<object>>(props: Props<T>) => {
  const {
    control,
    setValue,
    formState: { isSubmitting },
  } = useFormContext<z.infer<T>>();

  const { ...domProps } = props;

  const changeHandler = (
    newValue: SingleValue<Props<T>["options"][number]>,
  ) => {
    if (newValue === null) return;

    setValue(props.name, newValue.value, { shouldValidate: true });
  };

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field }) => {
        const selectedOption = props.options.find(
          (o) => o.value === field.value,
        );

        return (
          <ReactSelect<Props<T>["options"][number]>
            classNames={{
              control: () => controlStyles,
              dropdownIndicator: () => "text-neutral-500",
              menu: () => menuStyles,
              option: ({ isFocused, isSelected }) => {
                return twMerge(
                  "p-2 pl-3 text-neutral-900 bg-white",
                  isFocused && "font-bold bg-neutral-200",
                  isSelected && "font-bold",
                  props.className,
                );
              },
              placeholder: () => "text-neutral-500",
            }}
            id={domProps.id}
            isDisabled={isSubmitting}
            options={domProps.options}
            styles={{
              input: (base) => ({
                ...base,
                "input:focus": {
                  boxShadow: "none",
                },
              }),
              // On mobile, the label will truncate automatically, so we want to
              // override that behavior.
              multiValueLabel: (base) => ({
                ...base,
                whiteSpace: "normal",
                overflow: "visible",
              }),
              control: (base) => ({
                ...base,
                transition: "none",
              }),
            }}
            unstyled
            {...field}
            value={selectedOption}
            onChange={changeHandler}
          />
        );
      }}
    />
  );
};
