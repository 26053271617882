import { countryOptions } from "../../utils/countries";
import { Select } from "./Select";
import { Path, PathValue } from "react-hook-form";
import { DetailedHTMLProps, ReactNode, SelectHTMLAttributes } from "react";
import { z } from "zod";

const structuredOptions = countryOptions.map((country) => ({
  label: (
    <div className="flex flex-row items-center">
      <div className="text-xl pr-1.5">{country.emoji}</div>
      <div>{country.label}</div>
    </div>
  ),
  value: country.value,
}));

interface Props<T extends z.ZodSchema<object>>
  extends DetailedHTMLProps<
    SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  name: Path<z.infer<T>>;
}

export const CountrySelect = <T extends z.ZodSchema<object>>({
  name,
}: Props<T>) => {
  const options = structuredOptions as {
    label: ReactNode;
    value: PathValue<z.infer<T>, Props<T>["name"]>;
  }[];

  return <Select<T> name={name} options={options} />;
};
